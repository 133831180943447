.carousel {
    position: relative;
    width: 100%;
}

.carousel-container {
    position: relative;
    display: flex;
    gap: 1rem;
    overflow: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.carousel-item {
    flex: 0 0 100%;
    scroll-snap-align: start;
}

@media (min-width: 768px) {
    .carousel-item {
        flex: 0 0 33.3333%;
    }
}

.carousel .absolute.bottom-0.right-0.flex.space-x-2.p-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
}

.carousel button {
    width: 2rem;
    height: 2rem;
    text-align: center;
    opacity: 0.75;
    transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
    .carousel button {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.carousel button:hover {
    background-color: rgba(0, 0, 139, 0.75);
    opacity: 1;
}

.carousel button:disabled {
    opacity: 0.25;
    cursor: not-allowed;
}