@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/* *{
  border: #003249 solid 2px;
} */

.h2-georgia{
  font-family: 'Noto Sans Georgian', sans-serif;
  font-style: italic;
}
.gradient-background {
  background: linear-gradient(284.24deg, #6EBED5 -95.92%, #FFF8F4 100%);
}
.newsletter-placeholder {
  border:  1px solid #003249;
}
.newsletter-placeholder::placeholder{
  color: #003249; 
  padding: 1em;
}


.file-input {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #22c55e;
  border: 2px solid white;
  position: absolute;
  bottom: 2px;
  right: 2px;
  opacity: 0;
  cursor: pointer;
}

.bg-edit-icon {
  background-image: url('./assets/images/icon.png');
  background-size: cover;
  background-position: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  right: 2px;
  pointer-events: none;
}

.product-grid-item:nth-child(3n) {
  height: calc(2 * 60%);
}

:root {
  --Cobalt-Blue: #007ea7;
  --Sapphire-Blue: #6EBED5;
}

.placeholder-opacity-50::placeholder {
  font-weight: lighter;
  opacity: 0.5;
  color: #003249;
}

.block.transition.duration-500.ease-in-out {
  background: #1a4a71;
}


/* ----------------------------- react-easy-crop ---------------------------- */

.crop-container {
  position: relative;
  width: 236px;
  height: 236px;

  background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 70px 70px;
}

.reactEasyCrop_CropArea {
  color: rgba(255, 255, 255, 0.8) !important;
}